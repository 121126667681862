import { checkboxAnatomy as parts } from "@chakra-ui/anatomy";
import { ComponentStyleConfig } from "@chakra-ui/react";
import type {
	PartsStyleFunction,
	SystemStyleFunction,
} from "@chakra-ui/theme-tools";
import { mode } from "@chakra-ui/theme-tools";

const baseStyleLabel: SystemStyleFunction = (props) => {
	const { colorScheme: c } = props;
	return {
		transitionProperty: "color",
		transitionDuration: "fast",
		transitionTimingFunction: "ease-out",
		_checked: {
			color: mode(`${c}.500`, `${c}.200`)(props),
		},
	};
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
	label: baseStyleLabel(props),
});

const Checkbox: ComponentStyleConfig = { baseStyle };

export default Checkbox;

import { ThemeOverride } from "@chakra-ui/react";
import { locale } from "../data/locale";

const direction = locale === "ar" ? "rtl" : "ltr";
const styles: ThemeOverride["styles"] = {
	global: {
		":root": { fontSize: "14px", direction },
		".page": { minH: "calc(100vh - 75px)" },
		".iti": { w: "full" },
		".iti--allow-dropdown .iti__flag-container": {
			insetStart: 0,
			insetEnd: "auto",
		},
		".iti--allow-dropdown input[type=tel]": {
			direction: "ltr",
			textAlign: direction === "rtl" ? "right" : "left",
			pl: direction === "rtl" ? 4 : 14,
			pr: direction === "rtl" ? 14 : 4,
		},
		".iti__arrow": {
			me: "0",
			ms: "6px",
		},
		".iti__country": { direction: "ltr" },
		".iti__country-list": { textAlign: "start", shadow: "md" },
		".iti-mobile .iti--container": {
			zIndex: 9999,
			insetInline: "10px",
			w: "calc(100% - 20px)",
		},
	},
};

export default styles;

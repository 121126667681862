import React from "react";
import {
  Box,
  Image,
  HStack,
  Text,
  Heading,
  Link,
  LinkBox,
} from "@chakra-ui/react";
import DonationButton from "./DonationButton";
import { useLocation } from "react-router-dom";
import WhatsButton from "./WhatsButton";
import { useGetSettingsQuery } from "../../redux/services/settingsApi";

function DonationWhatsGroup() {
  const location = useLocation();
  const isPathDonation = location.pathname === "/donation";
  const { data: settings } = useGetSettingsQuery(undefined);
  return (
    <HStack
      pos="fixed"
      zIndex="20"
      bottom="20"
      spacing="3"
      right={{ base: "10", "2xl": "10%" }}
    >
      {settings?.whatsapp ? (
        <WhatsButton phoneNumber={settings?.whatsapp} />
      ) : null}
      {isPathDonation ? null : <DonationButton />}
    </HStack>
  );
}

export default DonationWhatsGroup;

import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { Box, Spinner, Container } from "@chakra-ui/react";
import ErrorBoundary from "./components/ErrorBoundary";
import routes from "./routes";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import DonationWhatsGroup from "./components/buttons/DonationWhatsGroup";
const News = lazy(() => import("./pages/News"));
const GovFiles = lazy(() => import("./pages/GovFiles"));

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Header />
      <Container maxW="80em">
        <DonationWhatsGroup />
      </Container>
      <Suspense
        fallback={
          <Box className="page" d="flex" justifyContent="center">
            <Spinner
              size="xl"
              thickness="4px"
              speed="0.65s"
              mt="8"
              emptyColor="gray.200"
              color="brand.500"
            />
          </Box>
        }
      >
        <Switch>
          {routes.map(({ path, exact, children, Component }, i) => {
            if (path === "/governance") return null;
            if (children) {
              return children.map(({ path, exact, Component }, i) => (
                <Route key={i} path={path} exact={exact}>
                  <ErrorBoundary>
                    <Component />
                  </ErrorBoundary>
                </Route>
              ));
            }
            return (
              <Route key={i} path={path} exact={exact}>
                <ErrorBoundary>
                  <Component />
                </ErrorBoundary>
              </Route>
            );
          })}
          <Route path="/governance/:id">
            <GovFiles />
          </Route>
          <Route path="/news/:newsId">
            <News />
          </Route>
        </Switch>
      </Suspense>
      <Footer />
    </div>
  );
}
export default App;

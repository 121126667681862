import { emptySplitApi } from "./emptySplitApi";

const govFileTypesApi = emptySplitApi.injectEndpoints({
	endpoints: (build) => ({
		showGovFileType: build.query<GovFileType, number>({
			query: (id) => `/governance-file-types/${id}`,
			transformResponse: (res: Res<GovFileType>) => res.data,
		}),
		getGovFileTypes: build.query<List<GovFileType>, number | undefined>({
			query: (page = 1) => `/governance-file-types?page=${page}`,
			transformResponse: ({ data, meta }: ListRes<GovFileType>) => ({
				items: data,
				page: meta.current_page,
				total: meta.total,
				total_pages: meta.last_page,
			}),
		}),
	}),
});

export const { useShowGovFileTypeQuery, useGetGovFileTypesQuery } = govFileTypesApi;

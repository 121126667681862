// using chakra convention for customizing its theme
// ref: https://chakra-ui.com/docs/theming/customize-theme#scaling-out-your-project
import {
	extendTheme,
	ThemeOverride,
	withDefaultColorScheme,
} from "@chakra-ui/react";
import "intl-tel-input/build/css/intlTelInput.css";
import "swiper/swiper.scss";
import "swiper/components/thumbs/thumbs.scss";
import "swiper/components/navigation/navigation.scss";
import "aos/dist/aos.css";
import { locale } from "../data/locale";
import foundations from "./foundations";
import components from "./components";
import styles from "./styles";

const direction = locale === "ar" ? "rtl" : "ltr";
const overrides: ThemeOverride = {
	...foundations,
	direction,
	styles,
	components,
};

export default extendTheme(
	overrides,
	withDefaultColorScheme({
		colorScheme: "brand",
		components: ["Button", "Input"],
	}),
	withDefaultColorScheme({
		colorScheme: "golden",
		components: ["Radio", "Checkbox", "Switch"],
	})
);

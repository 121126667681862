import { Button, HStack } from "@chakra-ui/react";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { useGetSettingsQuery } from "../../redux/services/settingsApi";

function StoreButton() {
  const { data: settings } = useGetSettingsQuery(undefined);
  const path = settings?.website;
  return path ? (
    <Button
      px={8}
      minW={"150px"}
      pb={{ md: "6" }}
      pt={{ md: "7" }}
      onClick={() => window.open(path)}
    >
      المتجر الإلكتروني
    </Button>
  ) : null;
}

export default StoreButton;

import { Image } from "@chakra-ui/image";
import { Box, Text, VStack } from "@chakra-ui/layout";
import React from "react";

interface QrCardProps {
  license_qr: string;
  license_number: string;
}

function QrCard({ license_qr, license_number }: QrCardProps) {
  console.log(license_number);
  return (
    <VStack width="auto">
      <Box display="inline-block" padding="4" pos="relative">
        <Image
          src={license_qr ?? ""}
          htmlWidth="140"
          htmlHeight="140"
          maxW={"140px"}
        />
      </Box>
      {license_number ? (
        <Text mt="3" textAlign="center" fontSize={"lg"}>
          رقم الترخيص : {license_number}
        </Text>
      ) : null}
    </VStack>
  );
}

export default QrCard;

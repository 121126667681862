import { ComponentStyleConfig } from "@chakra-ui/theme";

const Heading: ComponentStyleConfig = {
	sizes: {
		lg: {
			fontSize: "2xl",
			lineHeight: [1.33, null, 1.2],
		},
	},
};

export default Heading;

import { ThemeOverride } from "@chakra-ui/react";

const colors: ThemeOverride["shadows"] = {
	sm: "0px 0px 20px #C2C2C21A",
	md: "0px 3px 26px #C2C2C238",
	lg: "0px 0px 45px #C2C2C23B",
	brand: "-2px 2px 0 rgba(214, 158, 46, 0.24)",
	dark: "-2px 2px 0 rgba(0, 0, 0, 0.08)",
};

export default colors;

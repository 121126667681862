import { Box } from "@chakra-ui/react";
import { cloneElement } from "react";
import { WhatsappIcon } from "../Icons";

interface Props {
  phoneNumber: string;
}

function WhatsButton({ phoneNumber }: Props) {
  const url = "https://wa.me/" + phoneNumber;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "4px",
        p: "2",
        bg: "white",
        borderRadius: "200px",
        cursor: "pointer",
      }}
      onClick={() => window.open(url)}
      shadow="md"
    >
      {cloneElement(<WhatsappIcon />, {
        color: "brand.500",
        boxSize: "20",
        width: "54px",
        height: "54px",
        borderRadius: "50",
      })}
    </Box>
  );
}

export default WhatsButton;

import colors from "./colors"
import fonts from "./fonts"
import shadows from "./shadows"

const foundations = {
	colors,
	fonts,
	shadows
}

export default foundations;

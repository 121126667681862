import { ThemeOverride } from "@chakra-ui/react";

const colors: ThemeOverride["colors"] = {
	gray: {
		"50": "#f5f5f5",
		"100": "#ebebebe0",
		"400": "#909090",
	},
	persimmon: {
		"50": "#fef7f6",
		"100": "#fef0ec",
		"200": "#fcd9d0",
		"300": "#fac2b4",
		"400": "#f7957c",
		"500": "#f36744",
		"600": "#db5d3d",
		"700": "#b64d33",
		"800": "#923e29",
		"900": "#773221",
	},
	golden: {
		"50": "#fef9f4",
		"100": "#fcf4e9",
		"200": "#f8e3c9",
		"300": "#f3d1a8",
		"400": "#ebaf67",
		"500": "#e28d26",
		"600": "#cb7f22",
		"700": "#aa6a1d",
		"800": "#885517",
		"900": "#6f4513",
	},
	brown: {
		"50": "#fbf9f6",
		"100": "#f6f3ee",
		"200": "#e9e2d4",
		"300": "#dcd0b9",
		"400": "#c2ac85",
		"500": "#A88951",
		"600": "#977b49",
		"700": "#7e673d",
		"800": "#655231",
		"900": "#524328",
	},
	brand: {
		"50": "#fbf3f3",
		"100": "#f7e7e6",
		"200": "#ecc3c1",
		"300": "#e19f9b",
		"400": "#ca5750",
		"500": "#B30F05",
		"600": "#a10e05",
		"700": "#860b04",
		"800": "#6b0903",
		"900": "#580702",
	},
	dark: {
		"50": "#fdfdfd",
		"100": "#fcfcfc",
		"200": "#f7f7f7",
		"300": "#f2f2f2",
		"400": "#e8e8e8",
		"500": "#dedede",
		"600": "#c8c8c8",
		"700": "#a7a7a7",
		"800": "#858585",
		"900": "#6d6d6d",
	},
};

export default colors;

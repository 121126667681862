import Button from "./button";
import Container from "./container";
import Input from "./input";
import Textarea from "./textarea";
import Select from "./select";
import Modal from "./modal";
import Checkbox from "./checkbox";
import Radio from "./radio";
import Heading from "./heading";

const components = {
	Button,
	Container,
	Input,
	Select,
	Textarea,
	Modal,
	Checkbox,
	Radio,
	Heading
};

export default components;

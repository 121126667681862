import { configureStore } from "@reduxjs/toolkit";
import { emptySplitApi } from "./services/emptySplitApi";

const store = configureStore({
	reducer: {
		[emptySplitApi.reducerPath]: emptySplitApi.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(emptySplitApi.middleware),
});

export default store;

import { cloneElement } from "react";
import { FastDonationIcon } from "../Icons";
import { Text, HStack } from "@chakra-ui/react";
import { translation as t } from "../../data/locale";
import { useHistory } from "react-router-dom";
function DonationButton() {
  const history = useHistory();

  return (
    <HStack
      sx={{
        display: "flex",
        alignItems: "center",
        px: "3",
        gap: "4px",
        py: "2",
        bg: "white",
        color: "black",
        borderRadius: "200px",
        cursor: "pointer",
        pe: "8",
      }}
      shadow="md"
      onClick={() => history.push("/donation")}
    >
      {cloneElement(<FastDonationIcon />, {
        color: "brand.500",
        boxSize: "20",
        width: "54px",
        height: "54px",
        borderRadius: "50",
      })}
      <Text
        sx={{
          fontSize: "17px",
          fontWeight: "bold",
        }}
      >
        {t.fast_donate}
      </Text>
    </HStack>
  );
}

export default DonationButton;

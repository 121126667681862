import { ComponentStyleConfig } from "@chakra-ui/react";

const Button: ComponentStyleConfig = {
	sizes: {
		lg: {
			fontSize: "md"
		}
	},
	baseStyle: {
		borderRadius: "0",
	},
};

export default Button;

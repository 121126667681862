import { ComponentStyleConfig } from "@chakra-ui/react";
import Input from "./input";

const Select: ComponentStyleConfig = {
	sizes: Input.sizes,
	variants: Input.variants,
	defaultProps: Input.defaultProps,
};

export default Select;

import { FC } from "react";
import {
  Stack,
  Image,
  Grid,
  HStack,
  VStack,
  Heading,
  Text,
  Link,
  Box,
  Container,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { translation as t } from "../data/locale";
import { useGetSettingsQuery } from "../redux/services/settingsApi";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  LocationIcon,
  TwitterIcon,
} from "./Icons";
// images
import logo from "../images/footer-logo.png";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import QrCard from "./cards/QrCard";

const nav = [
  { children: t.home, to: "/" },
  { children: t.jobs, to: "/jobs" },
  { children: t["initiatives&projects"], to: "/initiatives&projects" },
  { children: t.contactUs, to: "/contact-us" },
  { children: t.committees, to: "/committees" },
  { children: t.donationCases, to: "/donations" },
  { children: t.governance, to: "/governance" },
  { children: t.reports, to: "/reports" },
];
const services = [
  { children: t.registerMembership, to: "/e-services/membership" },
  { children: t.registerBene, to: "/e-services/beneficiary" },
  { children: t.volunteer, to: "/e-services/volunteer" },
];
const about = [
  { children: t.chairmanTalk, to: "/about" },
  { children: t.strategy, to: "/about/strategy" },
  { children: t.structure, to: "/about/structure" },
  { children: t.foundingDecision, to: "/about/founding" },
];

type Props = {};
const Footer: FC<Props> = () => {
  const { data: settings } = useGetSettingsQuery(undefined);
  return (
    <Box as="footer" bgColor="#363636" color="white">
      <Grid
        as={Container}
        gridTemplateColumns={{
          base: "1fr",
          lg: settings?.license_qr ? "repeat(4, 1fr) 160px" : "repeat(4, 1fr)",
        }}
        gap={settings?.license_qr ? "4" : "10"}
        py="20"
      >
        <Image
          src={logo}
          alt="Baity logo"
          w="280px"
          alignSelf={{ md: "center" }}
        />
        {/* middle */}
        <Stack
          gridColumn={{ lg: "2 / span 2" }}
          direction={{ base: "column", md: "row" }}
          spacing="10"
          mx={{ lg: "auto" }}
        >
          <Grid
            as="ul"
            gridTemplateRows="repeat(4, 1fr)"
            rowGap="4"
            columnGap="10"
            gridAutoFlow={{ sm: "column" }}
            listStyleType="none"
          >
            {nav.map((item, i) => (
              <li key={i}>
                <Link as={RouterLink} {...item} />
              </li>
            ))}
          </Grid>
          <Stack
            direction={{ base: "column", sm: "row" }}
            spacing="10"
            sx={{ a: { color: "gray.300" } }}
          >
            <VStack align="flex-start" spacing="2.5" flexGrow={1}>
              <Heading as="h4" size="sm">
                {t.eServices}
              </Heading>
              {services.map((item, i) => (
                <Link key={i} as={RouterLink} {...item} />
              ))}
            </VStack>
            <VStack align="flex-start" spacing="2.5" flexGrow={1}>
              <Heading as="h4" size="sm">
                {t.aboutUs}
              </Heading>
              {about.map((item, i) => (
                <Link key={i} as={RouterLink} {...item} />
              ))}
            </VStack>
          </Stack>
        </Stack>
        {/* end middle */}
        <VStack
          spacing="4"
          align="flex-start"
          sx={{
            ".chakra-icon": { color: "golden.500" },
            ".chakra-link": { color: "gray.300" },
          }}
        >
          <Heading size="lg">{t.contactUs}</Heading>
          <Link href={settings?.map_location} target="_blank" rel="noopener">
            <LocationIcon me="2" />
            <span>{settings?.address}</span>
          </Link>
          <Stack direction={{ base: "column", sm: "row" }}>
            <Link href={"tel:" + settings?.phone}>
              <PhoneIcon me="2" />
              <span>{settings?.phone}</span>
            </Link>
            <Link href={"mailto:" + settings?.email}>
              <EmailIcon me="2" />
              <span>{settings?.email}</span>
            </Link>
          </Stack>
          <HStack
            sx={{
              ".chakra-icon": {
                boxSize: "8",
                p: "1.5",
                borderRadius: "full",
                borderWidth: "1px",
                borderColor: "currentColor",
              },
            }}
          >
            <Link href={settings?.twitter} target="_blank" rel="noopener">
              <TwitterIcon />
            </Link>
            <Link href={settings?.instagram} target="_blank" rel="noopener">
              <InstagramIcon />
            </Link>
            <Link href={settings?.facebook} target="_blank" rel="noopener">
              <FacebookIcon />
            </Link>
            <Link href={settings?.linkedin} target="_blank" rel="noopener">
              <LinkedInIcon />
            </Link>
          </HStack>
        </VStack>

        {settings?.license_qr ? (
          <QrCard
            license_number={settings?.license_number}
            license_qr={settings?.license_qr}
          />
        ) : null}
      </Grid>
      <Text p="3" textAlign="center" bgColor="brown.500">
        <small>{t.copyrights}</small>
      </Text>
    </Box>
  );
};

export default Footer;

import { emptySplitApi } from "./emptySplitApi";

const settingsApi = emptySplitApi.injectEndpoints({
	endpoints: (build) => ({
		getSettings: build.query({
			query: () => "/settings",
			transformResponse: (res: Res<Settings>) => res.data,
		}),
	}),
});

export const { useGetSettingsQuery } = settingsApi;

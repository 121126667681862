import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import type { PartsStyleObject } from "@chakra-ui/theme-tools";

const baseStyle: PartsStyleObject<typeof parts> = {
	dialog: { borderRadius: 0 },
	header: {
		py: 7,
		pos: "relative",
		textAlign: "center",
		color: "brand.500",
		_after: {
			content: `""`,
			pos: "absolute",
			w: "18%",
			h: "1px",
			bottom: "24%",
			insetStart: "50%",
			transform: "translateX(50%)",
			bgColor: "gray.200",
		},
	},
	footer: {
		py: 7,
		justifyContent: "center",
	},
};

const Modal = {
	baseStyle,
};
export default Modal;

import { createStandaloneToast } from "@chakra-ui/react";
import merge from "lodash/merge";
import { locale } from "../data/locale";
import theme from "../theme";

const toast = createStandaloneToast({ theme });

export const API_ROOT =
  (process.env.REACT_APP_API_URL as string) ?? "https://api.baity.org.sa/api";
if (!API_ROOT) {
  throw new Error("REACT_APP_API_URL env variable is missing!");
}

export async function appFetch<T>(
  path: string,
  config?: RequestInit
): Promise<T> {
  const fullPath = path.indexOf(API_ROOT) === -1 ? API_ROOT + path : path;
  const headers = { Accept: "application/json", "Accept-Language": locale };
  const res = await fetch(fullPath, merge({ headers }, config));
  const data = await res.json();

  if (res.ok) return data;

  // handle errors
  if (typeof data.errors === "object") {
    Object.values(data.errors).forEach((err) => {
      toast({
        description: err as string,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    });
  } else {
    let message: string =
      res.status === 0
        ? "Check your internet connection."
        : res.status === 500
        ? "Internal Server Error, Try again later."
        : data.message;
    toast({
      title: "خطأ",
      description: message,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  }
  throw data;
}

import { Component, ReactNode } from "react";
import { Container, Heading, Text } from "@chakra-ui/react";

interface Props {
  children: ReactNode;
}

class ErrorBoundary extends Component<Props> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error("Error", error);
    console.error("Error Info", errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container py="5" className="page">
          <Heading color="red.500" size="lg" mb="2">
            خطأ:&nbsp;
          </Heading>
          <Text>
            شئ ما خاطئ قد حدث، إذا كنت من مشرفي الموقع يمكنك رؤية رسالة تفصيلية
            عن الخطأ في كونسول المتصفح أو حاول الإتصال بمشرفي الموقع مع تزويدهم
            بصورة لهذا الخطأ
          </Text>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
